
import React, { useContext, useState } from "react";
import { GlobalDataContext } from "../../../context/context";
import { Link } from "react-router-dom";
import { BsArrowRightCircle, BsPlusLg } from "react-icons/bs";
import { HiOutlineViewGrid, HiQuestionMarkCircle } from "react-icons/hi";
import { BsBookmarkStar } from "react-icons/bs";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { BiCabinet } from "react-icons/bi";
import ScrollProgressBar from "../ScrollProgressBar";
import NavMenu from "./NavMenu";
import { FiCalendar, FiPhoneCall } from "react-icons/fi";
import { AiOutlineUnorderedList } from "react-icons/ai";
// import { MdMailOutline } from "react-icons/md";
import IconsRedes from "../IconsRedes";




function Header_5({ btnName }) {

  const { rpdata } = useContext(GlobalDataContext);
  const [offCanvas, setOffCanvas] = useState(false);
  const [subMenu, setSubMenu] = useState(false);

  //Recorrer el array de los servicios
  const subitems = rpdata?.dbServices?.map((item) => {
    return {
      link: `/${item.name.replaceAll(" ", "-").toLowerCase()}`,
      name: item.name,
    };
  });

  //sub menu
  const subitemsGallery = rpdata?.landings?.map((itemsGallery) => {
    return {
      link: `/gallery/${itemsGallery.name.replaceAll(" ", "-").toLowerCase()}`,
      name: itemsGallery.name,
    };
  });
  // menu


  const Navigation = [
    {
      name: "Home",
      path: "/",
      icon: <HiOutlineViewGrid className="mx-auto text-[20px]" />,
    },
    {
      name: "About",
      path: "/about",
      icon: <HiQuestionMarkCircle className="mx-auto text-[20px]" />,
    },
    {
      name: "Services",
      path: "/services",
      icon: <BiCabinet className="mx-auto text-[20px]" />,
      child: rpdata?.autoGntLandingFromService,
      submenu: [...(subitems ? subitems : [])],
    },
    {
      name: `Gallery`,
      path: `/gallery`,
      icon: <BiCabinet className="mx-auto text-[20px]" />,
      child: rpdata?.customLinks,
      submenu: [...(subitemsGallery ? subitemsGallery : [])],
    },
    {
      name: "Contact",
      path: "/contact",
      icon: <FaEnvelopeOpenText className="mx-auto text-[20px]" />,
    },
  ];


  // agregar la pestaña de reviews al array de dbMenu
  const el = {
    name: `Reviews`,
    path: `/reviews`,
    icon: <BsBookmarkStar className="mx-auto text-[20px]" />,
    child: false,
  };

  rpdata?.simpleWidgets?.forEach((element) => {
    const num = Navigation.length - 1;
    if (element.val === "ReviewTab" && element.activo === true) {
      Navigation.splice(num, 0, el);
    }
  });

  //  fin de agregar pestaña de reviews


  return (
    <div className="absolute z-10 w-[100%] md:py-0 py-0">
      <header>
        <ScrollProgressBar />
        <div className="w-[100%] bg-slate-100  shadow-lg">
          <div className="w-[80%] mx-auto flex md:flex-row flex-col pt-0 justify-center md:py-0">
            <div className="w-full md:w-[30%] flex justify-center ">
              <div className="md:w-[60%] w-[80%]">
                <div>
                  <img
                    src={rpdata?.dbPrincipal?.logo}
                    alt="LOGO"
                    loading="lazy"
                    className="md:my-1 my-8"
                  />
                </div>
              </div>
              <div className="flex self-center md:hidden w-[30%] justify-center">
                <AiOutlineUnorderedList
                  className="text-[30px] text-black"
                  onClick={() => {
                    setOffCanvas(!offCanvas);
                  }}
                />
              </div>
            </div>

            <div className="w-[70%] hidden md:flex md:flex-row justify-center flex-col self-center items-center">
              <div className="flex">
                {rpdata?.dbPrincipal?.phones.slice(0, 2)?.map((item, index) => {
                  return (
                    <ul key={index}>
                      <li className="md:py-2 py-4 flex">
                        <a
                          href={`tel:+1${item.phone}`}
                          className="md:mx-6 mx-2 items-center flex"
                        >
                          <FiPhoneCall className="md:text-[25px] text-[20px]" />
                          <span className="flex flex-col md:pt-[10px]">
                            <span className="md:pl-3 font-bold md:text-[18px] text-[14px]">
                              {item.phone}
                            </span>
                            {rpdata?.dbPrincipal?.phones?.[0].name.length > 0 ? (
                              <span className="md:pl-3 mt-[-5px]">{item.name}</span>
                            ) :
                              <span className="md:pl-3 mt-[-5px]">Phone</span>
                            }

                          </span>
                        </a>
                      </li>
                    </ul>
                  );
                })}
              </div>
              <div>
                <ul>
                  <li className="md:py-2 py-4 flex items-center">
                    <FiCalendar className="md:text-[25px] text-[20px] mx-1" />
                    <span className="flex flex-col md:pt-[10px] ">
                      <span className="md:pl-3 font-bold md:text-[18px] text-[14px] mr-5">
                        {rpdata?.dbPrincipal?.workHours?.[0].hour}
                      </span>
                      <span className="md:pl-3 mt-[-5px] ">
                        {rpdata?.dbPrincipal?.workdays?.[0].day}
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
              {/* <div>
              <ul>
                <li className="md:py-2 py-4 flex items-center">
                  <FiClock className="md:text-[25px] text-[20px] mx-1" />
                  <span className="flex flex-col md:pt-[10px] border-dotted border-black border-r-2">
                    <span className="md:pl-3 font-bold md:text-[18px] text-[14px] mr-5">
                      {rpdata?.dbPrincipal?.workHours?.[1].hour}
                    </span>
                    <span className="md:pl-3 mt-[-5px] ">
                      {rpdata?.dbPrincipal?.workdays?.[1].day}
                    </span>
                  </span>
                </li>
              </ul>
            </div> */}
              <div className="ml-3 md:flex flex-col justify-end items-center gap-3">
                <ul className="flex flex-grow gap-4 ">
                  {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                    return (
                      <li key={index} className="bg1 text-white w-[40px] flex justify-center rounded-xl">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                          <i className={`fab text-[20px] m-3 fa-${item.icon}`} aria-hidden="true" />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Modo Desktop Barra */}
      <div className="md:block hidden z-50">
        <nav className="w-[80%] mx-auto flex shadow-2xl px-32 relative bg2 md:mb-[0px]">
          <div className="p-1 py-4 flex w-full justify-between">

            <NavMenu />
            <Link className="flex w-[200px] justify-center items-center space-x-2 text-white"
              to={`/${btnName === 'view all services' ? 'services' : 'contact'}`}
            >
              <span className='capitalize text-white text-[18px] font-semibold'>
                {btnName ? btnName : 'Free Estimate'}
              </span>
              <BsArrowRightCircle size={18}/>
            </Link>

          </div>
        </nav>
      </div>
      {/* MenuMobil */}
      <nav>
        {offCanvas ? (
          <nav className="side-slide z-10">
            <button
              className="text-white relative top-[-30px] left-[210px] bg2 py-[6px] px-[15px] rounded-md"
              onClick={() => {
                setOffCanvas(false);
              }}
            >
              x
            </button>
            <div>
              <img
                src={rpdata?.dbPrincipal?.logo}
                alt="Company Logo"
                className="w-[80%] mb-8"
              />
            </div>
            <div>
              <ul className="bg-navbar-movil">
                {Navigation.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link
                        to={item.child ? "" : item.path}
                        className="flex self-center items-center"
                        onClick={() => {
                          setSubMenu(!subMenu);
                        }}
                      >
                        {item.name}
                        {item.child ? <BsPlusLg className="ml-[140px]" /> : null}
                      </Link>
                      {item.child ? (
                        <ul className="rounded-sm w-[200px] text-white relative ml-2 off-canvas">
                          {item.submenu.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="py-2 px-4"
                                onClick={() => {
                                  setOffCanvas(false);
                                }}
                              >
                                <Link to={item.link} exact>
                                  {item.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
              <div>
                <IconsRedes classes={'flex justify-center items-center gap-5'} />
              </div>
            </div>
          </nav>
        ) : null}
      </nav>
    </div>
  );
};

export default Header_5;